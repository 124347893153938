
import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css';
import HomeLayout from './layout/HomeLayout';
import UserLayout from './layout/UserLayout';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import EmailOTP from './pages/auth/otp/Email';

// import Home from './pages/Home';
import Profile from './pages/user/Profile';
import Test from './pages/Test'
import Dashboard from './pages/user/Dashboard';
import Investments from './pages/user/Investments';
import Packages from './pages/user/Packages';
import Downlines from './pages/user/Downlines';
import Deposit from './pages/user/Deposit';
import Security from './pages/user/Security';
import Transactions from './pages/user/Transactions';
import Transfer from './pages/user/Transfer';
import Withdrawal from './pages/user/Withdrawal';
import Tickets from './pages/user/Tickets';
import TicketMessage from './pages/user/TicketMessage';
// import TicketMessage from './pages/user/TicketMessage';
import Notfound from './pages/NotFoundPage';
import Trader from './pages/user/Trader';
import CopyTrader from './pages/user/CopyTrader';
import MyTrader from './pages/user/MyTrader';
import Trade from './pages/user/Trade';
import TradeHistory from './pages/user/TradeHistory';
import TokenWithdraw from './pages/user/TokenWithdraw';
import Portfolio from './pages/user/Portfolio';

function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path='' element={<Navigate to={'/user/dashboard'} />} ></Route>
        <Route path='/auth/login' element={<Login />} ></Route>
        <Route path='/auth/signup' element={<Register />} ></Route>
        <Route path='/auth/email/otp' element={<EmailOTP />} ></Route>
      </Route>
      <Route path='user' element={<UserLayout />}>
        <Route path='' element={<Navigate to={'/user/dashboard'} />} />
        <Route path='dashboard' element={<Dashboard />} ></Route>
        <Route path='investments' element={<Investments />} ></Route>
        <Route path='investment/packages' element={<Packages />} ></Route>
        <Route path='transaction/deposit' element={<Deposit />} ></Route>
        <Route path='transaction/withdraw' element={<Withdrawal />} ></Route>
        <Route path='transactions' element={<Transactions />} ></Route>
        <Route path='downlines' element={<Downlines />} ></Route>
        <Route path='copy-trader' element={<CopyTrader />} ></Route>
        <Route path='market' element={<MyTrader />} ></Route>
        <Route path='market/new-trade' element={<Trade />} ></Route>
        <Route path='trade-history' element={<TradeHistory />} ></Route>
        <Route path='copy-trader/:id' element={<Trader />} ></Route>
        <Route path='transfer_asset' element={<Transfer />} ></Route>
        <Route path='profile' element={<Profile />} ></Route>
        <Route path='security' element={<Security />} ></Route>
        <Route path='Portfolio' element={<Portfolio />} ></Route>
        <Route path='token/withdraw' element={<TokenWithdraw />} ></Route>
        <Route path='tickets' element={<Tickets />} ></Route>
        <Route path='ticket/:id' element={<TicketMessage />} ></Route>
        <Route path='test' element={<Test />} ></Route>
        <Route path='*' element={<Navigate to={'/404'} />} />
      </Route>
      <Route path='/404' element={<Notfound />} ></Route>
    </Routes>
  );
}

export default App;
