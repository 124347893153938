import React, { useEffect, useMemo, useState } from 'react'
import * as api from '../../services/api_service'
import AppHelpers from '../../hooks/AppHook'
import classNames from 'classnames'
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

function Portfolio() {

  const { currencyFormat, theme } = AppHelpers()
  const [portfolios, setPortfolios] = useState([])
  const [portfolioSymbols, setPortfolioSymbols] = useState([])
  const [isFetching, setisFetching] = useState(true)
  const [show, setShow] = useState(false)
  const [activeSymbol, setActiveSymbol] = useState({})
  const [tab, setTab] = useState("buy");
  const [buyUnitsInput, setBuyUnitsInput] = useState("");
  const [sellUnitsInput, setSellUnitsInput] = useState("");
  const [isSending, setisSending] = useState(false)

  async function getPortfolios() {
    try {
      const resp = await Promise.all([
        api.portfolios(),
        api.portfolioSymbols()
      ]);
      console.log('all', resp)
      setPortfolios(resp[0].data.data)
      setPortfolioSymbols(resp[1].data.data)
      setisFetching(false)
    } catch (error) {
      setisFetching(false)
      console.log(error)
    }
  }

  useEffect(() => {
    getPortfolios()
  }, [])

  const filteredPortfolioSymbols = useMemo(() => {
    if (portfolios.length === 0) return portfolioSymbols;
    const datas = new Set(portfolios.map(p => p.symbol.id));
    const filtered = portfolioSymbols.filter(s => !datas.has(s.id));
    return filtered;
  }, [portfolios, portfolioSymbols])



  const handleClose = () => {
    setActiveSymbol({});
    setShow(false);
    setBuyUnitsInput("");
    setSellUnitsInput("");
    setTab("buy");
  }

  const handleShow = (data) => {
    setActiveSymbol(data)
    setShow(true)
  }

  async function handleSend(type, amount) {
    setisSending(true);
    try {
      const resp = type === "buy" ? await api.buyPortfolio({ symbol_id: activeSymbol.id, units: buyUnitsInput, amount }) : await api.sellPortfolio({ symbol_id: activeSymbol.id, units: sellUnitsInput, amount })
      toast.success(resp.data.message, toastConfig)
      setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message, { ...toastConfig, autoClose: 8000 })
      setisSending(false)
    }
  }

  const toastConfig = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  }

  return (
    <>
      <div className="col-12 col-lg-8">
        <div className="row">
          <div className="col-12 mb-4">
            <div className={classNames("card border-gray-300", {
              "bg-dark-card": theme === "dark"
            })}>
              <div className="card-body d-flex flex-row align-items-center flex-0 border-bottom">
                <div className="d-block">
                  <div className={classNames("h5 fw-normal text-gray mt-2", {
                    "text-dark-white": theme === "dark"
                  })}>My Portfolio</div>
                </div>
                <div className="d-flex ms-auto">
                  {/*  */}
                </div>
              </div>
              <div className="card-body">

                <div>
                  {
                    !isFetching && portfolioSymbols.length > 0 ?
                      <>
                        {
                          portfolios.map((s, i) => (
                            <div key={i} className='px-3 py-3 d-flex flex-row align-items-center flex-0 shadow mb-3' style={{ backgroundColor: "white", borderLeft: "solid 6px #999" }}>
                              <div className='d-flex gap-3 align-items-center'>
                                <div style={{ width: "50px", height: "50px", backgroundColor: "rgba(200,200,200,0.4)", justifyContent: "center" }} className='d-flex align-items-center'>
                                  <div style={{ fontSize: "18px" }}>{s.symbol.name[0]}</div>
                                </div>
                                <div>
                                  <div style={{ fontSize: "15px", fontWeight: "bold" }}>{s.symbol.name}</div>
                                  <div style={{ fontSize: "13px" }}>{s.symbol.symbol}</div>
                                </div>
                              </div>
                              <div className='d-flex align-items-center ms-auto gap-3'>
                                <div style={{ textAlign: "right" }}>
                                  <div><b>units: {s.units}</b></div>
                                  <div style={{ color: (parseFloat(s.amount) === parseFloat(s.symbol.price) * s.units) ? "black" : parseFloat(s.amount) < (parseFloat(s.symbol.price) * s.units) ? "green" : "black" }}>
                                    {currencyFormat(parseFloat(s.symbol.price) * s.units)}
                                  </div>
                                </div>
                                <div onClick={() => handleShow(s.symbol)} className='d-flex align-items-center' style={{ width: "35px", height: "35px", backgroundColor: "rgba(200,200,200,0.4)", justifyContent: "center", borderRadius: "100%", cursor: "pointer" }}>
                                  <span
                                    className="icon icon-xs"><span className="fas fa-chevron-right"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                        {
                          filteredPortfolioSymbols.map((s, i) => (
                            <div key={i} className='px-3 py-3 d-flex flex-row align-items-center flex-0 shadow mb-3' style={{ backgroundColor: "white", borderLeft: "solid 6px #999" }}>
                              <div className='d-flex gap-3 align-items-center'>
                                <div style={{ width: "50px", height: "50px", backgroundColor: "rgba(200,200,200,0.4)", justifyContent: "center" }} className='d-flex align-items-center'>
                                  <div style={{ fontSize: "18px" }}>{s.name[0]}</div>
                                </div>
                                <div>
                                  <div style={{ fontSize: "15px", fontWeight: "bold" }}>{s.name}</div>
                                  <div style={{ fontSize: "13px" }}>{s.symbol}</div>
                                </div>
                              </div>
                              <div className='d-flex align-items-center ms-auto gap-3'>
                                <div style={{ textAlign: "right" }}>
                                  <div><b>0.00</b></div>
                                  <div>$0.00</div>
                                </div>
                                <div onClick={() => handleShow(s)} className='d-flex align-items-center' style={{ width: "35px", height: "35px", backgroundColor: "rgba(200,200,200,0.4)", justifyContent: "center", borderRadius: "100%", cursor: "pointer" }}>
                                  <span
                                    className="icon icon-xs"><span className="fas fa-chevron-right"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </> :
                      <>
                        <div>Fetching</div>
                      </>
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        centered
        className='mt-n7'
      >
        <Modal.Body className='pt-4'>
          <div className='col-12' style={{ 'padding': '5px 10px' }}>
            <div className='text-left'>
              {/* <div style={{ display: 'inline-block', padding: '5px 10px', borderRadius: '10px', fontSize: '13px' }} className='bg-primary text-white'>Selected Package</div><br />
              <div style={{ fontSize: '20px', marginLeft: 4, marginBottom: '10px' }}>{activePackage.name && (`${activePackage.name}`).toUpperCase()}</div> */}

              <div>
                <div className='d-flex mb-3 gap-1'>
                  <button className={`btn btn-sm px-3 ${tab === "buy" && "btn-primary"}`} onClick={() => setTab("buy")}>Buy</button>
                  <button className={`btn btn-sm px-3 ${tab === "sell" && "btn-primary"}`} onClick={() => setTab("sell")}>Sell</button>
                </div>

                {
                  tab === "buy" ?
                    <div className='mb-4'>
                      <div className='d-flex'>
                        <div><b>Buy {activeSymbol.name}</b></div>
                        <div className='ms-auto'>{currencyFormat(activeSymbol.price)}/unit</div>
                      </div>

                      <div>
                        <div className="row mt-3">
                          <div className="col-md-12 mb-3">
                            <div className="">
                              <label htmlFor="buy_units" >Units</label>
                              <input className="form-control" value={buyUnitsInput}
                                id="buy_units" onChange={(e) => setBuyUnitsInput(e.target.value)} type="text" placeholder="Enter Units" required />
                            </div>
                          </div>

                          <div className="col-md-12 mb-4">
                            <div className="">
                              <label htmlFor="buy_amount" >Amount</label>
                              <input className="form-control" value={(parseFloat(buyUnitsInput) * parseFloat(activeSymbol.price)) || 0}
                                id="buy_amount" type="text" placeholder="Enter Units" disabled />
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <div>
                              <button onClick={() => handleSend("buy", parseFloat(buyUnitsInput) * parseFloat(activeSymbol.price))} className="btn btn-primary" disabled={isSending || !buyUnitsInput}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> :
                    <div className='mb-4'>
                      <div className='d-flex'>
                        <div><b>Sell {activeSymbol.name}</b></div>
                        <div className='ms-auto'>{currencyFormat(activeSymbol.price)}/unit</div>
                      </div>

                      <div>
                        <div className="row mt-3">
                          <div className="col-md-12 mb-3">
                            <div className="">
                              <label htmlFor="sell_units" >Units</label>
                              <input className="form-control" value={sellUnitsInput}
                                id="sell_units" onChange={(e) => setSellUnitsInput(e.target.value)} type="text" placeholder="Enter Units" required />
                            </div>
                          </div>

                          <div className="col-md-12 mb-4">
                            <div className="">
                              <label htmlFor="sell_amount" >Amount</label>
                              <input className="form-control" value={(parseFloat(sellUnitsInput) * parseFloat(activeSymbol.price)) || 0}
                                id="sell_amount" type="text" placeholder="Enter Units" disabled />
                            </div>
                          </div>

                          <div className="col-md-12 mb-3">
                            <div>
                              <button onClick={() => handleSend("sell", parseFloat(sellUnitsInput) * parseFloat(activeSymbol.price))} className="btn btn-primary" disabled={isSending || !sellUnitsInput}>
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Portfolio